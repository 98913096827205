const modal = document.querySelector(".js-modal-card");
const modalMobil = document.querySelector(".js-modal-mobile-card");
const modalContent = document.querySelector(".js-flashbag__content");
const modalCloses = document.querySelectorAll(".js-modal-card__close");
const modalCloseMobile = document.querySelector(".js-modal-mobile-card__close");

if (modal) {
  modalCloses.forEach((modalClose) => {
    modalClose.addEventListener("click", function () {
      modal.classList.add("unactive");
    });
  });


  window.addEventListener("load", function () {
    let hide;

    function delayHide() {
      if (!hide) {
        hide = setTimeout(function () {
          modal.classList.add("unactive");
        }, 5000);
      }
    }

    delayHide();

    modalContent.addEventListener("mouseenter", () => {
      clearTimeout(hide);
      hide = null;
    });

    modalContent.addEventListener("mouseleave", () => {
      delayHide();
    });
  });
}

if (modalMobil) {
  modalCloseMobile.addEventListener("click", function () {
    modalMobil.classList.remove("d-flex");
    modalMobil.classList.add("d-none");
  });

  window.addEventListener("load", function () {
    setTimeout(function () {
      modalMobil.classList.remove("d-flex");
      modalMobil.classList.add("d-none");
    }, 7000);
  });
}
